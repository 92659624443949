<template>
      <div class="flex-column flex-lg-row-auto w-100 mb-10">
        <ResponsableTechniqueDetailDeclaration :declaration="declaration"/>
        <DocumentDetailDeclaration :declaration="declaration"/>
        <CommentsDeclaration :declaration="declaration"/>
      </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, defineProps } from "vue";
import DocumentDetailDeclaration  from './DocumentDetailDeclaration.vue';
import ResponsableTechniqueDetailDeclaration  from './ResponsableTechniqueDetailDeclaration.vue';
import CommentsDeclaration from "./CommentsDeclaration.vue";

const props = defineProps({
   declaration: Object,
});

onMounted(() => {
});
</script>
