
import { defineComponent, getCurrentInstance, onMounted, ref, computed } from "vue";
import { Field, ErrorMessage, FieldArray, useForm } from "vee-validate";

export default defineComponent({
  name: "SelectReseauModal",
  props: {
    declarationId: { String, required: true}
  },
  components: {
    Field,
    ErrorMessage,
    FieldArray ,
  },
  setup(props) {

    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;
    const vfm = getCurrentInstance()?.proxy?.$vfm;

    const title = ref("");
    const showModal = ref(false);

    const closeModal = () => {
      showModal.value = false;
      dismissModal();
    }

    const dismissModal = () => {
      showModal.value = false;
    }

    onMounted(() => {
      emitter.on('open-select-reseau', () => {
        vfm?.show('selectReseauModal');
      });
    });

    return {
      dismissModal,
      closeModal,
      title,
      showModal
    };
  }
});
