
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: "detail-declaration-navigation",
  setup() {
    const route = useRoute();
    const blrTab = ref<HTMLElement>();
    const key = ref(0);

    onMounted(() => {
      key.value++;
    });

    return {
      blrTab,
      key
    }
  }
});

