
import { computed, defineComponent, onMounted, ref, defineProps } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";

interface Declaration {
  id: string,
  numeroLicence: string
}

export default defineComponent({
  name: "documents-declaration",
  components: {

  },
  props: {
    declaration: Object
  },

  setup(props) {

    const store = useStore();
    let reseaux = ref([]);
    let documents = ref<any>([]);
    let typesReseau = ref<string[]>([
      'blr', 'fh', 'gsm', 'radar', 'vsat', 'pmr'
    ])
    const DOCUMENT_ROOT_PATH = ref(process.env.VUE_APP_DOCUMENT_ROOT);

    onMounted(() => {
      getDocuments();
    });

    const getDocuments = () => {
      typesReseau.value.forEach((e) => {

        //On recupere les reseaux par déclaration
        store.dispatch(Actions.GET_RESEAUX_BY_DECLARATION, {declarationId: props.declaration?.id, typeReseau: e, size: 100})
          .then((reseaux) => {
            
            // On parcourt ensuite les réseaux pour récupérer les documents
            reseaux.content.forEach((reseau) => {
              store.dispatch(Actions.GET_DOCUMENTS_BY_RESEAU, {reseauId: reseau.id, urlParameters: {size: 20}})
                .then((res) => {
                  //On parcourt la liste des documents récupérés par réseau et on insère dans la diste des documents à afficher
                  res.content.forEach((doc) => {
                    documents.value.push(doc);
                  })
                });
            });
            
          });
      })
    }

    return {
      documents,
      DOCUMENT_ROOT_PATH
    }
  }
})

