<template>
      <!--begin::Card-->
      <div class="card mb-5 mb-xl-8">
        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Details toggle-->
          <div class="d-flex flex-stack fs-4 py-3">
            <div
              class="fw-bold rotate collapsible"
              data-bs-toggle="collapse"
              href="#kt_responsables"
              role="button"
              aria-expanded="false"
              aria-controls="kt_responsables"
            >
              <i class="fa fa-user me-2"></i> Responsables Techniques

            </div>
          </div>
          <!--end::Details toggle-->

          <div class="separator separator-dashed my-3"></div>

          <!--begin::Details content-->
          <div id="kt_responsables" class="overscroll-block">
            <div v-for="(res,index) in props.declaration.responsableTechniques" :key="index">
                <div class="d-flex align-items-center mb-3">
                        <!-- <div class="symbol symbol-75px me-3">
                            <img src="	https://preview.keenthemes.com/metronic8/demo1/assets/media/stock/600x600/img-49.jpg" class="" alt="">
                        </div> -->
                        <div class="d-flex  flex-column">
                            <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 parent-ellipsis ">
                              <span class="text-ellipsis">{{res.nom}} {{res.prenom}}</span>
                            </a>
                            <span class="parent-ellipsis">
                                <span title="" class="text-gray-400 fw-semibold  fs-7 text-ellipsis">{{res.fonction}}</span>
                            </span>
                            <span class="text-gray-400 fw-semibold  fs-7 parent-ellipsis">
                                <span class="text-ellipsis">
                                  <i class="las la-envelope"></i>  
                                  {{res.email}}           
                                </span>
                            </span>
                            <span class="text-gray-400 fw-semibold fs-7 parent-ellipsis">
                              <span class="text-ellipsis">
                                <i class="las la-phone fs-1x"></i>
                                {{res.telephone}}
                              </span>
                              <span class="text-ellipsis" v-if="res.telephone2">
                                <i class="las la-phone fs-1x"></i>
                                {{res.telephone2}}
                              </span>                                    
                            </span>
                        </div>
                    </div>
            </div>
          </div>
          <!--end::Details content-->

          <!-- <div class="mt-8 d-flex justify-content-end">
            <button type="button" class="btn btn-light" :disabled="declaration.responsableTechniques.length <= 0">Tous les Responsables</button>
          </div> -->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
</template>

<script setup>
import { defineComponent, onMounted, ref, defineProps } from "vue";

const props = defineProps({
   declaration: Object,
});

onMounted(() => {
});

</script>
<style scoped>
.text-ellipsis {
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.parent-ellipsis {
      min-width: 0;
    flex: 1;
    display: grid;
}
.overscroll-block {
  max-height: 330px;
  overflow: scroll;
}
</style>
