
import { computed, defineComponent, onMounted, ref, defineProps } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";

interface Declaration {
  id: string,
  numeroLicence: string
}

export default defineComponent({
  name: "comments-declaration",
  components: {

  },
  props: {
    declaration: Object
  },

  setup(props) {

    const store = useStore();
    let commentaires = ref<any>([]);

    onMounted(() => {
      getComments();
    });

    const getComments = () => {
      store.dispatch(Actions.GET_COMMENTS_BY_DECLA, props.declaration?.id)
          .then((res) => {
            commentaires.value = res;
          });
    }

    return {
      commentaires,
    }
  }
})

